import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { URL_GES_DOC, URL_HOME_REL, URL_IMPUTACIONES, URL_INTRANET, URL_PROYECTOS } from 'src/app/common/constantes';
import { Role } from 'src/app/model/session';
import { SessionService } from 'src/app/services/session-service';
import { environment } from '../../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PanelMenu } from 'primeng/panelmenu';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  animations: [
    trigger('menuState', [
      state('opened', style({
        opacity: 1,
        visibility: 'visible'
      })),
      state('closed', style({
        width: '0',
        opacity: 0,
        visibility: 'hidden'
      })),
      transition('opened <=> closed', [
        animate('200ms ease-in-out')
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  items: MenuItem[] = [];
  compact: boolean = false;
  @ViewChild('panelMenu') panelMenu!: PanelMenu;

  constructor(public router: Router, public session: SessionService, private sessionService: SessionService,
    private route: ActivatedRoute
  ) {
    // this.route.queryParamMap.subscribe(params => {
    //   // es la llamada desde la intranet
    //   if (params.get('token')) {
    //     this.session.comprobarToken(params.get('token')!, params.get('appkey')!).subscribe(response => {
    //       if (response) {
    //         this.session.saveLoginData(response);
    //         this.router.navigateByUrl("/home");
    //       } else {
    //         this.router.navigateByUrl(URL_INTRANET);
    //       }
    //     })
    //   }
    // });
  }

  ngOnInit() {
    this.items = [
        {
            label: 'Inicio',
            routerLink: URL_HOME_REL,
            icon: 'pi pi-fw pi-home',
            styleCompact: {
              margin: '16px 4px 16px 0px'
            }
        },
        {
            label: 'Imputaciones',
            routerLink: URL_IMPUTACIONES,
            icon:'pi pi-fw iconImpu',
            styleCompact: {
              margin: '16px 4px 16px 0px'
            }
        },
        {
            label: 'Proyectos',
            routerLink: URL_PROYECTOS,
            icon: 'pi pi-fw pi-chart-bar',
            styleCompact: {
              margin: '16px 4px 16px 0px'
            }
        },
        {
          label: 'Gestión documental',
          routerLink: URL_GES_DOC,
          icon: 'pi pi-fw pi-folder',
          styleCompact: {
            margin: '16px 4px 16px 0px'
          }
      },
        {
            label: 'Configuración',
            icon: 'pi pi-fw pi-cog',
            /* styleClass: this.session.esAdmin ? 'custom-menu-item': 'hidden', */
            styleCompact: {
              margin: '16px 4px 16px 0px'
            },
            visible: this.tieneRol(Role.ADMIN) || this.tieneRol(Role.DIRECTOR) || this.tieneRol(Role.GESTOR) || this.tieneRol(Role.RRHH),
            items: [
                {
                    label: 'Tareas',
                    routerLink: 'configuracion/tareas',
                    style: {
                      'margin-left': '2em'
                    }
                },
                {
                    label: 'Beneficiarios',
                    routerLink: 'configuracion/beneficiarios',
                    style: {
                      'margin-left': '2em'
                    }
                },
                {
                    label: 'Tipos de documento',
                    routerLink: 'configuracion/tipos-documento',
                    style: {
                      'margin-left': '2em'
                    }
                },
                {
                  label: 'Conceptos salariales',
                  routerLink: 'configuracion/conceptos-salariales',
                  style: {
                    'margin-left': '2em'
                  }
              }
            ]
        },
        {
          label: 'Salir',
          icon: 'pi pi-fw pi-sign-out',
          command: () => this.logout(),
          style: {
            'position': 'fixed',
            'bottom': '2%',
            'margin': '16px 4px 16px 0px'
          },
          styleCompact: {
            'margin': 'auto 4px 16px 0',
            'position': 'fixed',
            'bottom': '2%'
          }
        },
    ];
  }

  navegar(item:MenuItem){
    if (item.label == 'Salir') {
      this.logout();
    } else {
      this.router.navigateByUrl(item.routerLink ? item.routerLink : this.router.url);
      this.compact = !this.compact;
      this.expandirItem(item);
    }
  }

  expandirItem(item:MenuItem): void {
    if (this.panelMenu && this.panelMenu.containerViewChild) {
      const items = this.panelMenu.containerViewChild.nativeElement.querySelectorAll('.p-panelmenu-panel');
      items.forEach((elem:any) => {
        const header = elem.querySelector('.p-panelmenu-header');
        if (elem.textContent.includes(item.label) && !this.items.find(item2 => item.label === item2.label)!.expanded) {
          header.click();
        }
      });
    }
  }

  activo(item: any) {
    return (this.router.url.slice(1) == item.routerLink || item.items?.find((i: any) => i.routerLink == this.router.url.slice(1))) && ' active';
  }

  logout() {
    if (environment.environmentName == 'development') {
      this.router.navigateByUrl("/login").then((respuesta) => {
        if (respuesta) {
          this.session.logout()
        }
      });
    } else {
        window.location.href =  URL_INTRANET;
    }
  }

  tieneRol(role: string){
    return this.sessionService.role.some(rol => rol.nombre == role);
  }

}
