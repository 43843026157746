import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {


  public exportAsExcelFile(json: any[], excelFileName: string): void {  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);  
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    // fijas ancho automatico en columnas
    this.fixWidth(worksheet, json);
    this.estiloCabecera(worksheet);
    this.setFomat(worksheet);

    XLSX.writeFile(workbook, excelFileName + ".xlsx");
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    //this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});   
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private estiloCabecera(worksheet: XLSX.WorkSheet) {
    for (let i = 0; i < worksheet["!cols"]!.length; i++) {
      // Aquí va el código que deseas ejecutar repetidamente
      let cell_address = {c:i, r:0};
      let cell_ref  = XLSX.utils.encode_cell(cell_address);
      worksheet[cell_ref ].s = {font: 
                                  { 
                                    sz: "11",
                                    bold: true,
                                    color: {rgb: "FFFFFF"}
                                  },
                                fill: {
                                  bgColor: {rgb: 'FF0000'},
                                  fgColor: {rgb: 'FF0000'},
                                }
                              };
    }
  }

  private setFomat(worksheet: XLSX.WorkSheet) {
    const range = XLSX.utils.decode_range(worksheet['!ref']!);

    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
        const cell = worksheet[cellAddress];
        // si es un número se le cambia el tipo de celda y formato de visualización
        if (typeof cell.v === 'string' && !isNaN(Number(+cell.v.replaceAll('.', '').replaceAll(',', '.')))) {
          cell.v = cell.v.replaceAll('.', '').replaceAll(',', '.');
          cell.t = 'n';
          cell.z = '#,##0.0';
        } else if (typeof cell.v === 'boolean'){
          cell.v = cell.v ? 'Si' : 'No';
          cell.t = 's';
        }
      }
    }
  }

  private fixWidth(worksheet: XLSX.WorkSheet, data: any[]) {
    let numCabeceras = 0;
    let colLengths: any = [];
    data.forEach(a => {
      if (Object.keys(a).length > numCabeceras) {
        numCabeceras = Object.keys(a).length;
        colLengths = Object.keys(a).map(k => k?.length)
      }
    });
   
    for (const d of data) {
      Object.values(d).forEach((element: any, index) => {
        const length = element?.toString().length
        if (colLengths[index] < length) {
          colLengths[index] = length
        }
      })
    }
    worksheet["!cols"] = colLengths.map((l:any) => {
      return {
        wch: l,
      }
    })
  }
}